import React, { useEffect } from 'react'
import { onPageEnter } from 'lib/tracking/pageEnter'
import Meta from 'components/modules/SEO/Meta'
import { GetStaticProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { RestructuredMain, RestructuredMobile } from 'components/layouts/Layouts.css'
import HeaderComponent from 'components/feature/Homepage/HeaderComponent'
import HeaderMobileComponent from 'components/feature/Homepage/HeaderMobileComponent'
import AdvantagesComponent from 'components/feature/Homepage/AdvantageComponent'
import LearningMaterialsComponent from 'components/feature/Homepage/LearningMaterialsComponent'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { getBecomeKnowerVideo } from 'lib/constants/homepage'
import useMedia from 'lib/hooks/useMedia'
import { deviceSize } from 'lib/constants/GridBreakpoints'
import dynamic from 'next/dynamic'
import { parseLocale } from 'lib/constants/localization'
import _useTranslation from 'lib/hooks/_useTranslation'
import { getCountryConfig } from 'lib/constants/countryconfig'
import { appFeedsRoute, expansionRoute } from 'lib/constants/routes'
import { isDeviceMobile } from 'lib/features/deviceInfo'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/reducer'
import ProFunnelEntryPoints from 'components/feature/Homepage/ProFunnelEntryPoints'
import AITutorComponent from 'components/feature/Homepage/AITutorComponent'
import { getCountryLanguageConfig } from 'lib/constants/countryLanguageConfig'
import { isExpansionContentCompetitionLive } from 'lib/features/expansion'

const PositiveReviewsComponent = dynamic(() => import('components/feature/Homepage/PositiveReviewsComponent'), {
    ssr: false,
})
const BecomeKnowerComponent = dynamic(() => import('components/feature/Homepage/BecomeKnowerComponent'))
const CodeComponent = dynamic(() => import('components/feature/Homepage/CodeComponent'))
const FAQComponent = dynamic(() => import('components/feature/Homepage/FAQComponent'), { ssr: false })

const ScrollSnapContainer = styled.div`
    @media (max-width: 750px) {
        scroll-snap-points-y: repeat(800px);
        scroll-snap-type: y proximity;
        height: 800px;
        overflow: auto;
        .scroll {
            scroll-snap-stop: always;
            scroll-snap-align: start;
        }
    }
`

const Homepage = () => {
    const { t } = _useTranslation('homepage')
    const router = useRouter()
    const { locale } = router
    const md = useMedia(deviceSize.md)
    const parsedLocale = parseLocale(locale)
    const countryConfig = getCountryConfig(parsedLocale.countryCode)
    const authenticatedUser = useSelector((state: AppState) => state.user)
    const hasBecomeKnowerVideo = !!getBecomeKnowerVideo(parsedLocale.contentLanguageCode)
    const isMobile = isDeviceMobile()

    const hasKnowunityPro = countryConfig?.hasPlusPlan

    const showWebappConversionEntryPoint = !!authenticatedUser && !isMobile

    useEffect(() => {
        onPageEnter()

        if (showWebappConversionEntryPoint) {
            router.push(appFeedsRoute)
        }
    }, [router, showWebappConversionEntryPoint])

    return (
        <div>
            <Meta title="" description={t('homepage/pageDescription')} />
            {!md ? (
                <RestructuredMain isWide>
                    <HeaderComponent />
                    {/* <VideosComponent /> */}
                    <AdvantagesComponent />
                    <LearningMaterialsComponent />
                    <AITutorComponent />
                    {hasKnowunityPro ? <ProFunnelEntryPoints /> : null}
                    {hasBecomeKnowerVideo ? (
                        <BecomeKnowerComponent contentLanguageCode={parsedLocale.contentLanguageCode} />
                    ) : null}
                    <CodeComponent />
                    <PositiveReviewsComponent />
                    {!countryConfig.hideHomepageFAQ && <FAQComponent />}
                </RestructuredMain>
            ) : null}
            <RestructuredMobile isWide>
                <ScrollSnapContainer id="snap-scroll">
                    <div className="scroll">
                        <HeaderMobileComponent />
                    </div>
                    {/* <div className="scroll">
                        <VideosComponent isMobile />
                    </div> */}
                    <div className="scroll">
                        <AdvantagesComponent isMobile type="know" />
                    </div>
                    <div className="scroll">
                        <AdvantagesComponent isMobile type="ai-tutor" />
                    </div>
                    <div className="scroll">
                        <LearningMaterialsComponent isMobile />
                    </div>
                    <div className="scroll">
                        <AITutorComponent />
                    </div>
                    {hasKnowunityPro ? (
                        <div className="scroll">
                            <ProFunnelEntryPoints isMobile />
                        </div>
                    ) : null}
                    {hasBecomeKnowerVideo ? (
                        <div className="scroll">
                            <BecomeKnowerComponent isMobile contentLanguageCode={parsedLocale.contentLanguageCode} />
                        </div>
                    ) : null}
                    <div className="scroll">
                        <CodeComponent isMobile />
                    </div>

                    <div className="scroll">
                        <PositiveReviewsComponent isMobile />
                    </div>
                    {!countryConfig.hideHomepageFAQ && <FAQComponent isMobile />}
                </ScrollSnapContainer>
            </RestructuredMobile>
        </div>
    )
}

export const getServerSideProps: GetStaticProps = async (props) => {
    const countryLanguageConfig = getCountryLanguageConfig(props.locale)

    const redirectHomepageToExpansionLandingpage = isExpansionContentCompetitionLive(countryLanguageConfig)
    if (redirectHomepageToExpansionLandingpage) {
        // Temporarily disabled due to Instapage usage
        return {
            redirect: {
                destination: expansionRoute,
                permanent: false,
            },
        }
    }

    return {
        props: {
            ...(await serverSideTranslations(parseLocale(props.locale).interfaceLanguageCode, ['common', 'homepage'])),
        },
    }
}

export default Homepage

import { Grade } from 'interfaces/Grade'
import { Subject } from 'interfaces/Subject'
import styled, { css } from 'styled-components'
import SchoolIcon from 'assets/icons/education.svg'
import SchoolDarkIcon from 'assets/icons/education_dark.svg'
import { KnowStatus, KnowType } from 'interfaces/Know'
import { ReactNode } from 'react'
import Link from 'next/link'
import { complimentingColor, colorToCssFilter } from 'lib/features/color'
import CheckIcon from 'assets/icons/small-check.svg'
import { useTranslation } from 'next-i18next'
import { formatKnowStatus } from 'lib/features/Know/know'
import { searchResultRouteOptions } from 'lib/constants/routes'
import { Currency } from 'interfaces/Currency'
import theme from 'stories/utils/theme'
import { SchoolType } from 'interfaces/SchoolType'
import { ButtonText } from 'stories/elements/Typography/Text'
import { formatAmount } from 'lib/formatting/amount'

export const BadgeWrapper = styled.div<{
    readonly align: 'left' | 'right'
    fullWidth?: boolean
    forceMobileAlignment?: boolean
}>`
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    color: #c2c7cc;
    align-items: center;
    justify-content: ${(props) => (props.align === 'left' ? 'flex-start' : 'flex-end')};
    ${({ fullWidth }) =>
        fullWidth
            ? css`
                  width: 100%;
              `
            : ''}

    span {
        margin: 3px;
    }
    @media (max-width: 750px) {
        ${({ forceMobileAlignment }) =>
            !forceMobileAlignment
                ? css`
                      justify-content: center;
                  `
                : ''}
    }
`

const BadgeElement = styled.span<{
    readonly textColor: string | null
    readonly backgroundColor: string
    readonly isSmall?: boolean
    readonly hasIcon?: boolean
    readonly isTransparent?: boolean
}>`
    color: ${(props) => (!props.isSmall ? props.textColor || 'blue' : theme.colors.actionGray)};
    background: ${(props) => (!props.isSmall ? props.backgroundColor || 'blue' : '#F5FAFC')};
    border-radius: 5px;
    font-weight: 550;
    padding: 0px 10px;
    margin: 0 6px 0px 0;
    display: flex;
    align-items: center;
    min-height: ${({ isSmall }) => (isSmall ? 20 : 32)}px;
    @media (max-width: 750px) {
        margin: 0 6px 6px 0;
    }

    & .icon {
        display: flex;
        align-items: center;
        fill: ${(props) => (!props.isSmall ? props.textColor || 'blue' : theme.colors.body2Black)} !important;
    }

    & img {
        height: 14px;
    }
    & p {
        margin: 0;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: ${({ textColor, isSmall }) => (isSmall ? theme.colors.actionGray : textColor ?? theme.colors.actionGray)};
    }

    ${({ isSmall }) =>
        isSmall
            ? css`
                  color: ${theme.colors.body2Black};
                  height: 25px !important;
                  padding: 0 7.5px;
                  border: 1px solid ${theme.colors.violet};
                  & .icon {
                      fill: ${theme.colors.body2Black} !important;
                  }
              `
            : ''}

    ${({ isSmall, hasIcon }) =>
        isSmall && !hasIcon
            ? css`
                  ::before {
                      position: relative;
                      top: 0.5px;
                      right: 1px;
                      content: ' ';
                      width: 12px;
                      height: 12px;
                      background-color: ${theme.colors.aiChatBotBlue}66;
                      border-radius: 3px;
                      margin-right: 5px;
                  }
              `
            : ''}
`

interface BadgeProps {
    text: string
    backgroundColor: string
    textColor: string
    icon?: ReactNode
    isSmall?: boolean
}

export const Badge = (props: BadgeProps) => (
    <BadgeElement
        backgroundColor={props.backgroundColor}
        textColor={props.textColor}
        isSmall={props.isSmall}
        hasIcon={!!props.icon}
    >
        {props.icon ? <div className="icon">{props.icon} &nbsp;</div> : null}
        <ButtonText fontFamily="Inter" fontWeigth={400}>
            {props.text}
        </ButtonText>
    </BadgeElement>
)

const FilteredImage = styled.span<{ readonly filter: string }>`
    & img {
        filter: ${(props) => props.filter};
    }
`

export const SubjectBadge = (props: {
    subject: Subject
    shortenSubjectName?: boolean
    isSmall?: boolean
    disallowLinking?: boolean
    isTransparent?: boolean
}) => {
    const color = props.subject.color

    const formatSubjectName = (subjectName: string) =>
        props.shortenSubjectName && subjectName?.length > 8 ? `${subjectName.slice(0, 8)}...` : subjectName

    const BadgeElement = () => (
        <Badge
            isSmall={props.isSmall}
            textColor={props.isTransparent ? theme.colors.white : props.subject.color}
            backgroundColor={props.isTransparent ? theme.colors.transparent : complimentingColor(color.toLowerCase())}
            text={formatSubjectName(props.subject.name)}
            icon={
                <FilteredImage filter={props.isTransparent ? theme.colors.white : colorToCssFilter(color)}>
                    <img src={props.subject.iconUrl} alt="" height={16} width={16} />
                </FilteredImage>
            }
        />
    )

    return (
        <>
            {props.disallowLinking ? (
                <BadgeElement />
            ) : (
                <Link href={searchResultRouteOptions('', props.subject.id)} prefetch={false} legacyBehavior>
                    <a>
                        <BadgeElement />
                    </a>
                </Link>
            )}
        </>
    )
}

export const GradeBadge = (props: {
    grades: Grade[]
    schoolType?: SchoolType
    isSmall?: boolean
    isTransparent?: boolean
}) => {
    if (!props.grades.length) return null

    const text = props.grades
        .slice(0, 2)
        .map((grade) => grade.shortName)
        .join('/')

    return (
        <Badge
            textColor={props.isTransparent ? theme.colors.white : 'var(--secondary-color)'}
            backgroundColor={props.isTransparent ? theme.colors.transparent : 'rgba(24, 130, 255, 0.2)'}
            text={`${text}${props?.schoolType && props?.schoolType.name !== 'DEFAULT' ? ` - ${props.schoolType.name}` : ''}`}
            icon={props.isSmall ? <SchoolDarkIcon /> : <SchoolIcon />}
            isSmall={props.isSmall}
        />
    )
}

export const KnowTypeBadge = (props: { knowType: KnowType; isSmall?: boolean }) => {
    return (
        <Badge
            textColor={theme.colors.body2Black}
            backgroundColor="rgba(97, 99, 102, 0.15)"
            text={props.knowType.name}
            isSmall={props.isSmall}
        />
    )
}

export const KnowStatusBadge = (props: { status: KnowStatus; isSmall?: boolean }) => {
    const { t } = useTranslation('knows')
    if (props.status === 'PUBLIC') return null

    return (
        <Badge
            textColor="#ff4848"
            backgroundColor="rgba(255, 72, 72, 0.2)"
            text={formatKnowStatus(t, props.status)}
            isSmall={props.isSmall}
        />
    )
}

export const AnsweredQuestionBadge = () => {
    return <Badge textColor={theme.colors.body2Black} backgroundColor="#ddfacf" text="Gelöst" icon={<CheckIcon />} />
}

export const PayAmountBadge = (props: { amount: number; currency: Currency }) => {
    return (
        <Badge
            textColor={theme.colors.body2Black}
            backgroundColor={theme.colors.lightBorder}
            text={formatAmount(props.amount, props.currency).split(' ').reverse().join('⠀')}
        />
    )
}
